import React, { useState } from "react";
import { Button, Modal, message } from "antd";

import "./App.css";
import {
  ConnectWallet,
  useSwitchChain,
  useNetwork,
  useAddress,
} from "@thirdweb-dev/react";
import { PolygonZkevm, PolygonZkevmTestnet } from "@thirdweb-dev/chains";
import { useContract } from "@thirdweb-dev/react";
import * as ethers from "ethers";
import { useEffect } from "react";

function App() {
  const [isCongratulations, setIsCongratulations] = useState(false);
  const switchChain = useSwitchChain();
  const [{ data, errorNetwork, loading }, switchNetwork] = useNetwork();
  const address = useAddress();
  const { contract, isLoading, errorContract } = useContract(
    // "0x36d1bBf6C57511635E074C81d3D75f89C4236A79", // zkEVM testnet
    "0xc9B296F2d7708147298cB22FB55A6Fa7d73FB6c1", // zkEVM
    [
      {
        inputs: [],
        name: "buy",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "ref",
            type: "address",
          },
        ],
        name: "buyRef",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
    ]
  );

  const showCongratulations = () => {
    message.destroy();
    message.open({
      type: "success",
      content:
        "mint success, please check your wallet, and add token to wallet",
      duration: 10,
    });
    setIsCongratulations(true);
    setTimeout(() => {
      setIsCongratulations(false);
    }, 4.5 * 1000);
  };

  const buy = async (amount = 0.0001) => {
    setOpen(false);
    const urlParm = window.location.pathname.split("/");
    try {
      message.destroy();
      message
        .open({
          type: "loading",
          content: "check your wallet, and confirm the transaction in wallet.",
          duration: 4,
        })
        .then(() =>
          message.open({
            type: "loading",
            content: " waiting for transaction confirmation",
            duration: 100,
          })
        );
      if (
        urlParm[1] === "ref" &&
        urlParm[2] !== undefined &&
        urlParm[2].length === 42
      ) {
        const req = await contract.call("buyRef", [urlParm[2]], {
          value: ethers.utils.parseEther(amount.toString()),
        });
        // console.log("ref", req, req.receipt.transactionHash);
        showCongratulations();
      } else {
        const req = await contract.call("buy", [], {
          value: ethers.utils.parseEther(amount.toString()),
        });
        // console.log("req", req, req.receipt.transactionHash);
        showCongratulations();
      }
    } catch (e) {
      message.destroy();
      message.open({
        type: "error",
        content: "check your wallet, and confirm the transaction in wallet",
        duration: 6,
      });
    }
  };

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = (e) => {
    console.log(e);
    setOpen(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setOpen(false);
  };

  return (
    <div class='container area'>
      <div class='card p-4'>
        <div class=' image d-flex flex-column justify-content-center align-items-center'>
          {" "}
          <button class='btn btn-secondary avatar'>
            <img src='../yoaka.png' height='120' />
          </button>{" "}
          <span class='name mt-3'>Yoaka</span>{" "}
          <a class='idd' href='/redirect?url=https://twitter.com/under_insight'>
            @under_insight
          </a>
          <div class='d-flex flex-row justify-content-center align-items-center gap-2'>
            {" "}
            <span class='idd2'>
              Claim your $YOAKA <br /> get a chance to win #airdrop
            </span>
          </div>
          <div class='d-flex flex-row justify-content-center align-items-center mt-3'>
            {" "}
            <span class='number'>100 $YOAKA = 0.0001 $ETH</span>{" "}
          </div>
          <div class=' d-flex mt-2'>
            <span class='btn1'>
              {!data?.chain ? (
                <ConnectWallet />
              ) : data?.chain.chainId === PolygonZkevm.chainId ? (
                <>
                  <ConnectWallet />
                  <div class='mint' onClick={showModal}>
                    Mint
                    <br />
                    $YOAKA
                  </div>{" "}
                  <Modal
                    title='Mint $YOAKA 🦑'
                    open={open}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <Button
                      type='primary'
                      block
                      size='large'
                      onClick={() => buy(0.0001)}
                    >
                      Mint $YOAKA 100 = 0.0001 ETH
                    </Button>
                    <Button
                      type='primary'
                      block
                      size='large'
                      onClick={() => buy(0.0005)}
                    >
                      Mint $YOAKA 500 = 0.0005 ETH
                    </Button>
                    <Button
                      type='primary'
                      block
                      size='large'
                      onClick={() => buy(0.001)}
                    >
                      Mint $YOAKA 1,000 = 0.001 ETH
                    </Button>
                    <Button
                      type='primary'
                      block
                      size='large'
                      onClick={() => buy(0.1)}
                    >
                      Mint $YOAKA 100,000 = 0.1 ETH
                    </Button>
                  </Modal>
                </>
              ) : (
                <div
                  class='btn1-change-network'
                  onClick={() => switchChain(PolygonZkevm.chainId)}
                >
                  Switch to PolygonZkevm
                </div>
              )}
            </span>{" "}
          </div>
          <div class='text mt-3'>
            {" "}
            <span>
              1️⃣ Follow{" "}
              <a
                class='isLink'
                href='/redirect?url=https://twitter.com/under_insight'
              >
                @under_insight
              </a>{" "}
              <a
                class='isLink'
                href='/redirect?url=https://twitter.com/Whoschat_io'
              >
                @whoschat_io
              </a>
              <br />
              2️⃣ Mint 100 $YOAKA (cost ≈ 0.2U)
              <br />
              3️⃣ Tweet with your customised link
              <br />
            </span>{" "}
          </div>
          <div class='gap-3 mt-3 icons d-flex flex-row justify-content-center align-items-center'>
            {" "}
            <a href='/redirect?url=https://twitter.com/under_insight'>
              <i class='fa fa-twitter'></i>
            </a>{" "}
            <a href='/redirect?url=https://www.instagram.com/yoaka_/'>
              <i class='fa fa-instagram'></i>
            </a>{" "}
            <a href='/redirect?url=https://discord.gg/GXDpWXFfcU'>
              <img src='../discord.png' height='15' style={{ marginTop: -5 }} />
            </a>{" "}
          </div>
          <br />
          {address ? (
            <a
              class='join'
              href={`/redirect?url=https://twitter.com/intent/tweet?text=I%20just%20claimed%20%24YOAKA%20on%20%400xpolygon%20%23zkEVM!%20%F0%9F%9F%A3%F0%9F%A6%91%0A%0A%F0%9F%91%87%F0%9F%8F%BBUse%20my%20link%20to%20win%20%40under_insight%20%23airdrop%20now!%0A%0Ahttps://yoaka.whoschat.io/ref/${address}`}
            >
              Click here to share your invite link 👇🏻
              <br />
              https://yoaka.whoschat.io/ref/{address}
            </a>
          ) : null}
        </div>
      </div>
      <ul class='circles'>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <img
        class={isCongratulations ? "congratulations" : null}
        src='../yoaka.gif'
        height='100%'
        style={{ display: "none" }}
      />
    </div>
  );
}

export default App;
